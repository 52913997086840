<template>
    <div class="EcaMax">
        <!-- 中间 -->
        <div class="EcaMiddle">
            <!-- 标题 -->
            <div class="EcaTitle"> <span style="margin-left:5vh;">评价宝贝</span> </div>
            <!-- 中间 -->
            <div class="EcaMiddleOne">
                <!-- 左边 -->
                <div class="EcaLeft">
                    <!-- 图片 -->
                    <div class="EcaPicture"> <img :src="ShopXing.imgs" alt=""
                            style="width:100%;height:100%;"> </div>
                    <!-- 标题 -->
                    <div class="EcaTitleOne">{{ShopXing.title}}</div>
                </div>
                <!-- 右边 -->
                <div class="EcaRight">
                    <!-- 评价 -->
                    <div class="EcaAppraise">
                        <!-- 好评 -->
                        <div class="EcaFlex">
                            <!-- 空白圆 -->
                            <div class="EcaBlankOne" v-if="circle" @click="ClickHereOne()"></div>
                            <div class="EcaBlank" v-else @click="ClickHere()"></div>
                            <!-- 好评图片 -->
                            <img src="../../../assets/CulturalCreation/evaluate/good.png" alt="" style="height:80%;">
                        </div>
                        <!-- 一般般 -->
                        <div class="EcaFlex">
                            <!-- 空白圆 -->
                            <div class="EcaBlankOne" v-if="ordinary" @click="ReviewOne()"></div>
                            <div class="EcaBlank" v-else @click="review()"></div>
                            <!-- 好评图片 -->
                            <img src="../../../assets/CulturalCreation/evaluate/ordinary.png" alt="" style="height:80%;">
                        </div>
                        <!-- 差评 -->
                        <div class="EcaFlex">
                            <!-- 空白圆 -->
                            <div class="EcaBlankOne" v-if="difference" @click="NegativeOne()"></div>
                            <div class="EcaBlank" v-else @click="negative()"></div>
                            <!-- 好评图片 -->
                            <img src="../../../assets/CulturalCreation/evaluate/bad.png" alt="" style="height:80%;">
                        </div>
                    </div>
                    <!-- 输入框 -->
                    <div class="EcaTextarea">
                        <textarea class="EcaText" placeholder="填写评价内容" v-model="text" cols="30" rows="10">
                        
                        </textarea>
                    </div>
                    <!-- 按钮 -->
                    <div class="EcaButton" @click="submit()">发表评论</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 输入内容
            text:'',
            // 好评
            circle: false,
            // 中评
            ordinary: false,
            // 差评
            difference: false,
            // 商品信息
            ShopXing:{},
            // 评论的好差
            commentLevel:0,
        }
    },
    created(){
        this.shop()
    },
    methods: {
        // 好评点击切换圆
        ClickHere() {
            this.circle = true;
            this.ordinary = false;
            this.difference = false;
            this.commentLevel = 1;
            console.log('好', this.commentLevel);
        },
        ClickHereOne() {
            this.circle = false
        },
        // 中评
        review() {
            this.ordinary = true;
            this.circle = false;
            this.difference = false;
            this.commentLevel = 2;
            console.log('中', this.commentLevel);

        },
        ReviewOne() {
            this.ordinary = false
        },
        // 差评
        negative() {
            this.difference = true;
            this.circle = false;
            this.ordinary = false
            this.commentLevel = 3;
            console.log('差', this.commentLevel);
        },
        NegativeOne() {
            this.difference = false;

        },
        // 商品信息
        shop(){
            this.ShopXing = this.$route.query; 
            console.log('商品信息', this.ShopXing);
        },
        // 提交评价
        submit(){
            if( this.commentLevel == 0){
                this.$message.error('请选择评价类型')
                return
            }
            if( this.commentLevel == ''){
                this.$message.error('请输入评价内容')
                return
            }
            var axios = require('axios');
            var config = {
                method: 'POST',
                url: this.$Schttp +'/vtp/app/orderComment/addComment',
                // params:{
                //     GoodsCodeid:this.GoodsCodeid
                // }
                headers: {
                             Authorization : 'Bearer ' + this.$cookies.get("token"), 
                             'Content-Type': 'application/json'
                  },
                  data:{
                    orderId:this.ShopXing.orderId,
                    objectId:this.ShopXing.GoodsId,
                    commentContent:this.text,
                    commentLevel:this.commentLevel,
                  }
            };
            axios(config)
                .then((res) => {
                    console.log('提交', res );
                    if (res.data.code == 200) {
                        this.$message({
                        message: '评价成功',
                        type: 'success'
                      });
                        this.$router.push('/ShopAtWillHome');
                    }
                   
                    
                })
                .catch(function (error) {
                });
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.EcaMax {
    width: 100%;
    height: 100%;
    background-color: rgba(206, 206, 206, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    .EcaMiddle {
        width: 80%;
        height: 80%;
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        align-items: center;

        .EcaTitle {
            width: 100%;
            font-size: 3vh;
            font-weight: 300;
            color: rgba(72, 72, 72, 1);
            margin-top: 3vh;
        }

        .EcaMiddleOne {
            width: 80%;
            height: 60%;
            margin-top: 7vh;
            display: flex;
            justify-content: space-between;

            .EcaLeft {
                width: 20%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .EcaPicture {
                    width: 100%;
                    height: 30%;
                    margin-top: 3vh;
                }

                .EcaTitleOne {
                    font-size: 2vh;
                    color: rgba(122, 122, 122, 1);
                    margin-top: 2vh;
                }
            }

            .EcaRight {
                width: 73%;
                height: 100%;
                .EcaAppraise {
                    width: 50%;
                    height: 8%;
                    display: flex;
                    justify-content: space-between;

                    .EcaFlex {
                        width: 15%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .EcaBlank {
                            width: 30%;
                            height: 55%;
                            border: 1px solid rgba(112, 112, 112, 1);
                            border-radius: 50%;
                            cursor: pointer;
                        }

                        .EcaBlankOne {
                            width: 30%;
                            height: 55%;
                            background-color: rgba(67, 209, 101, 1);
                            border: 1px solid rgba(67, 209, 101, 1);
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    }
                }

                .EcaTextarea {
                    width: 100%;
                    height: 60%;
                    margin-top: 3vh;

                    .EcaText {
                        width: 100%;
                        height: 100%;
                        resize: none;
                        outline: none;
                        border: 1px solid rgba(112, 112, 112, 1);
                        color: #333333;
                        padding:2vh;
                        
                    }

                    textarea::-webkit-input-placeholder {
                        /* WebKit browsers */
                        color: rgba(184, 184, 184, 1);
                      
                     
                    }
                }
                .EcaButton{
                     width:23%;
                     height:11.5%;
                     background-color:rgba(154, 212, 232, 1);
                     border-radius:0.5vh;
                     font-size:2vh;
                     color: rgba(255, 255, 255, 1);
                     display: flex;
                     justify-content:center;
                     align-items: center;
                     cursor: pointer;
                     margin-top:7vh;
                }
            }
        }
    }
}</style>